import colors from "./colors";

// should be tailwind.defaultTheme.fontFamily.sans but build system doesn't let me
const defaultSans = [
  "ui-sans-serif",
  "system-ui",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  '"Noto Sans"',
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  '"Noto Color Emoji"',
];

export const tailwindConfig = {
  theme: {
    extend: {
      fontFamily: {
        sans: ["Satoshi", ...defaultSans],
      },
      colors: {
        primary: colors.orange,
        black: colors.black,
        blue: colors.blue,
        gray: colors.gray,
        green: colors.green,
        orange: colors.orange,
        red: colors.red,
        white: colors.white,
        yellow: colors.yellow,
      },
    },
  },
};
