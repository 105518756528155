const blue = {
	50: '#DFF7FB',
	100: '#B0ECF4',
	200: '#7ADFED',
	300: '#3DD1E5',
	400: '#00C8DF',
	500: '#00BED9',
	600: '#00AEC6',
	700: '#0099AB',
	800: '#18474A',
	900: '#1D3435',
	950: '#00494D',
};

const gray = {
	50: '#F3F2F7',
	100: '#F2EBEB',
	200: '#BFBFC2',
	300: '#AAAAAD',
	400: '#8C8787',
	500: '#828284',
	600: '#4D4A4A',
	700: '#403E3E',
	800: '#333131',
	900: '#292828',
	950: '#252424',
};

const green = {
	50: '#E6F5EC',
	100: '#C2E6D0',
	200: '#9BD6B3',
	300: '#72C795',
	400: '#51BB7F',
	500: '#2BAF69',
	600: '#24A05F',
	700: '#1B8E52',
	800: '#25593D',
	900: '#233D2F',
	950: '#034525',
};

const orange = {
	50: '#F9E9E7',
	100: '#FCCCBD',
	200: '#FAAC93',
	300: '#EC7852',
	400: '#EB5220',
	500: '#CE481C',
	600: '#AB401D',
	700: '#DE4F1F',
	800: '#66301F',
	900: '#44281F',
	950: '#322420',
};

const red = {
	50: '#FFECF0',
	100: '#FFCED6',
	200: '#F19BA1',
	300: '#E8757D',
	400: '#F5535D',
	500: '#FB4044',
	600: '#EC3743',
	700: '#DA2D3C',
	800: '#CD2635',
	900: '#BE1829',
	950: '#7A0F1A',
};

const yellow = {
	50: '#FDF8E0',
	100: '#FBECB2',
	200: '#F8E07F',
	300: '#F6D54A',
	400: '#F5CA1D',
	500: '#F5B300',
	600: '#F5B300',
	700: '#F6A000',
	800: '#936b00',
	900: '#4B3D1A',
	950: '#362F1D',
};

const white = {
	DEFAULT: '#FEFEFF',
	50: '#FEFEFF',
	100: '#FEFEFF',
	200: '#FEFEFF',
	300: '#FEFEFF',
	400: '#FEFEFF',
	500: '#FEFEFF',
	600: '#FEFEFF',
	700: '#FEFEFF',
	800: '#FEFEFF',
	900: '#FEFEFF',
	950: '#FEFEFF',
};
// const white = '#FEFEFF';

const black = '#212020';

module.exports = {
	blue,
	green,
	gray,
	orange,
	red,
	yellow,
	white,
	black,
};
